import {Box, Card, CardContent, CardHeader, Divider, Grid, TextField} from "@mui/material";

export const AccountProfileDetails = ({userInfo}) => {

    return (
        <form
            autoComplete="off"
            noValidate
        >
            <Card>
                <CardHeader
                    subheader="Bilgiler"
                    title="Profiliniz"
                />
                <Divider/>
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Adı"
                                name="firstName"
                                required
                                value={userInfo?.name}
                                variant="outlined"
                                disabled='disabled'
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Soyadı"
                                name="lastName"
                                required
                                value={userInfo?.surname}
                                variant="outlined"
                                disabled='disabled'
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Eposta"
                                name="email"
                                required
                                value={userInfo?.email}
                                variant="outlined"
                                disabled='disabled'
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Telefon"
                                name="phone"
                                type="number"
                                value={userInfo?.phone}
                                variant="outlined"
                                disabled='disabled'
                            />
                        </Grid>

                    </Grid>
                </CardContent>
                <Divider/>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                >
                </Box>
            </Card>
        </form>
    );
};