import React, {useState} from 'react';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {makeStyles} from "@mui/styles";
import {Avatar, Card, CardActions, CardContent, CardHeader, Grid, IconButton} from "@mui/material";

const Teacher = (props) => {

    const teacher = props.teacher;


    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        orange: {
            color: '#fff',
            backgroundColor: '#ED6C02',
        },
    }));

    const classes = useStyles();

    const [state, setState] = useState({
        raised: false,
        shadow: 1,
    })

    return (

        <Card className="card-content"
              onMouseOver={() => setState({raised: true, shadow: 4})}
              onMouseOut={() => setState({raised: false, shadow: 1})}
              raised={state.raised} zdepth={state.shadow}
        >
            <CardHeader
                avatar={<Avatar aria-label="recipe" className={classes.orange}>
                    {teacher.name.charAt(0).toUpperCase()} {teacher.surname.charAt(0).toUpperCase()}
                </Avatar>}
                action={<IconButton aria-label="settings">
                    <MoreVertIcon/>
                </IconButton>}
                title={teacher.name + ' ' + teacher.surname}
            />
            <CardContent>

                <Grid container direction="row" alignItems="center" alignContent="stretch">
                    <Grid item>
                        <Avatar variant="rounded" alt="Kurum Müdürü" className={classes.orange}>
                            <AssignmentIndIcon/>
                        </Avatar>
                    </Grid>
                    <Grid item>
                        {teacher.institute.name}
                    </Grid>
                </Grid>

            </CardContent>

            <CardActions>

            </CardActions>
        </Card>
    );
};

export default Teacher;
