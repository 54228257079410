import React from 'react';
import {Link} from 'react-router-dom';
import {Grid} from "@mui/material";

const ShowError = () => (
    <div>
        <h1>Oppss</h1>
        <h2>Erişim hatası</h2>
        <h3>Bağlantınızı kontrol edip tekrar deneyiniz!</h3>
        <Grid item>
            <Link to="/">Ana Sayfa</Link>
        </Grid>

        <Grid item>
            Yeniden giriş yapmak için 'Giriş' tıklayınız. <Link to="/login">Giriş</Link>
        </Grid>
    </div>
);

export default ShowError;