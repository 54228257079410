import axios from "./index";

class Trainers {
    static get = () => {
        return axios.get(`${base}`);
    };
}

let base = "/api/trainers";

export default Trainers;
