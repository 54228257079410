import React, {ReactNode, useEffect, useState} from 'react';
import "./InstituteMeasureList.css";
import InstituteMeasure from "./InstituteMeasure";
import {Grid} from "@mui/material";

function Item(props: { children: ReactNode }) {
    return null;
}

const InstituteMeasureList = (props) => {

    const [isShowList, setIsShowList] = useState(false);

    const listItems = props.instituteMeasures.map((instituteMeasure) =>
        <InstituteMeasure key={instituteMeasure.id} instituteMeasure={instituteMeasure}
                          showActionButton={true}/>
    );

    useEffect(() => {
        setIsShowList(false);
        if (listItems && listItems.length) {
            setIsShowList(true);
        }
    }, []);

    return (
        <div>
            {
                listItems.length !== 0 &&
                <h2>ÖLÇÜMLER</h2>
            }
            <Grid
                container
                direction="row"

                alignItems="flex-start"
            >
                {listItems}
            </Grid>

        </div>
    );
};

export default InstituteMeasureList;

