import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import CalculateIcon from '@mui/icons-material/Calculate';

export const TotalSummary = ({title, total, color = 'primary', ...other}) => (
    <Card {...other}>
        <CardContent>
            <Grid
                container
                spacing={3}
                sx={{justifyContent: 'space-between'}}
            >
                <Grid item>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="overline"
                    >
                        {title}
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="h4"
                    >
                        {total}
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar
                        sx={{
                            backgroundColor: `${color}.main`,
                            height: 56,
                            width: 56
                        }}
                    >
                        <CalculateIcon/>
                    </Avatar>
                </Grid>
            </Grid>

        </CardContent>
    </Card>
);
