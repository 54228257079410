import React from 'react';
import {Link} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Button, Grid} from "@mui/material";


const Logout = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const action = key => (
        <Button onClick={() => {
            closeSnackbar(key)
        }}>
            Kapat
        </Button>
    );

    localStorage.removeItem("token");

    enqueueSnackbar('Başarılı çıkış tamamlandı ', {
        variant: 'warning',
        persist: true,
        action
    });


    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            style={{height: '50vh'}}
        >
            <Grid item>
                Başarılı çıkış tamamlandı !!!
            </Grid>
            <Grid item>
                Yeniden giriş yapmak için 'Giriş' tıklayınız.
            </Grid>

            <Grid item>
                <Link to={`/login`}>
                    <Button variant="contained" color="primary">Giriş</Button>
                </Link>
            </Grid>
        </Grid>
    );
}

export default Logout;
