import axios from "./index";

class Institutes {
    static get = () => {
        return axios.get(`${base}`);
    };

    static getClassLevels = (instituteId) => {
        return axios.get(`${base}/class-levels/${instituteId}`);
    };
}

let base = "/api/institutes";

export default Institutes;
