import React from 'react';
import InstituteDashboard from "./dashboard/InstituteDashboard";

function Dashboard(props) {

    return (
        <InstituteDashboard/>
    );
}

export default Dashboard;