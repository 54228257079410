import axios from "./index";

class Seasons {
    static getActive = () => {
        return axios.get(`${base}/active`);
    };
}

let base = "/api/seasons";

export default Seasons;
