import React, {useContext, useEffect, useState} from 'react';
import Classroom from "./Classroom";
import "./ClassroomList.css";
import ClassroomApi from "../../api/Classrooms";
import {useParams} from "react-router-dom";
import ListAlert from "../ListAlert";
import AddIcon from "@mui/icons-material/Add";
import ClassroomEdit from "./ClassroomEdit";
import {Fab, Grid} from "@mui/material";
import {SeasonContext} from "../../config/SeasonContext";

const ClassroomList = () => {

    const {instituteId, measureId} = useParams();

    const [classrooms, setClassrooms] = useState([]);

    const [openClassroomDialog, setOpenClassroomDialog] = useState(false);

    const season = useContext(SeasonContext);

    const handleClickClassroomOpen = () => {
        setOpenClassroomDialog(true);
    };

    const closeClassroomDialog = () => {
        setOpenClassroomDialog(false);
    };

    const getClassrooms = () => {
        ClassroomApi.getByInstituteIdAndSeasonId(instituteId, season?.id).then(response => setClassrooms(response.data.data));
    }

    useEffect(() => {
        getClassrooms();
    }, []);

    const listItems = classrooms.map((classroom) =>
        <Classroom key={classroom.id} classroom={classroom} measureId={measureId}
                   showActionButton={true}/>
    );

    return (
        <div>
            <h2>SINIFLAR
                <Grid container direction="column-reverse" alignItems="center" alignContent="end">
                    <Grid item style={{marginLeft: 'auto'}}>
                        <Fab color="primary" onClick={handleClickClassroomOpen} aria-label="Sınıf Ekle">
                            <AddIcon/>
                        </Fab>
                    </Grid>
                </Grid>
            </h2>

            <ListAlert listItems={listItems}></ListAlert>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                {listItems}
            </Grid>


            <ClassroomEdit closeDialog={closeClassroomDialog} isOpen={openClassroomDialog} instituteId={instituteId}/>
        </div>
    );
};

export default ClassroomList;
