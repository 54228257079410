import {makeStyles} from "@mui/styles";
import {Box, LinearProgress, Typography} from "@mui/material";

const useStyles = makeStyles({
    root: {
        height: 25,
        borderRadius: 5
    },
    bar: ({ progress }) => ({
        borderRadius: 5,
        background: `linear-gradient(90deg, #6fcbb6 ${100 - progress}%, #9c64f4 100%)`
    })
});

export const Progress = ({ progress = 0 }) =>  {
    const classes = useStyles({ progress });

    return (

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress classes={{ root: classes.root, bar: classes.bar }}
                                variant="determinate"
                                value={progress}
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`%${Math.round(progress,)}`}</Typography>
            </Box>
        </Box>
    );
}