import React, {useEffect, useState} from "react";
import Dashboard from "./pages/Dashboard";
import {SnackbarProvider} from "notistack";
import {HashRouter, Route, Routes} from "react-router-dom";
import {SeasonContext} from "./config/SeasonContext";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Account from "./pages/Account";
import ClassroomList from "./component/classroom/ClassroomList";
import StudentList from "./component/student/StudentList";
import Measure from "./pages/measure/Measure";
import TeacherList from "./component/teacher/TeacherList";
import NotFound from "./pages/NotFound";
import SeasonApi from "./api/Seasons";
import ResponsiveAppBar from "./component/navbar/ResponsiveAppBar";
import MeasureStatus from "./pages/measure/MeasureStatus";
import ShowError from "./pages/ShowError";

const App = () => {

    const [season, setSeason] = useState(null);

    const getSeason = () => {
        SeasonApi.getActive().then(response => setSeason(response.data.data));
    }
    useEffect(() => {
        getSeason();
    }, []);

    return (
        <SnackbarProvider preventDuplicate maxSnack={25}>
            <HashRouter>
                <SeasonContext.Provider value={season}>
                    <ResponsiveAppBar/>
                    <Routes>
                        <Route path="/" element={<Dashboard/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/account" element={<Account/>}/>
                        <Route path="/classroom/:instituteId" element={<ClassroomList/>}/>
                        <Route path="/classroom/:classroomId/student" element={<StudentList/>}/>
                        <Route path="/classroom/:instituteId/measure/:measureId" element={<ClassroomList/>}/>
                        <Route path="/measure/:measureId/classroom/:classroomId" element={<Measure/>}/>
                        <Route path="/measure/status/:measureId" element={<MeasureStatus/>}/>
                        <Route path="/teacher/:instituteId" element={<TeacherList/>}/>
                        <Route path="/measures" element={<Dashboard/>}/>
                        <Route path="/errors" element={<ShowError/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </SeasonContext.Provider>
            </HashRouter>
        </SnackbarProvider>
    );
}

export default App;
