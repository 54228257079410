import axios from "./index";

class Teachers {
    static getByInstituteId = (instituteId) => {
        return axios.get(`${base}/institutes/${instituteId}`);
    };

    static save = (data) => {
        return axios.post(`${base}`, data);
    };
}

let base = "/api/teachers";

export default Teachers;
