import {Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography} from "@mui/material";

export const AccountProfile = ({userInfo}) => (<Card>
    <CardContent>
        <Box
            sx={{
                alignItems: 'center', display: 'flex', flexDirection: 'column'
            }}
        >
            <Avatar
                src={userInfo?.avatar}
                sx={{
                    height: 64, mb: 2, width: 64
                }}
            />
            <Typography
                color="textPrimary"
                gutterBottom
                variant="h5"
            >
                {`${userInfo?.name} ${userInfo?.surname}`}
            </Typography>
            <Typography
                color="textSecondary"
                variant="body2"
            >

            </Typography>
            <Typography
                color="textSecondary"
                variant="body2"
            >
                {userInfo?.username}
            </Typography>
        </Box>
    </CardContent>
    <Divider/>
    <CardActions>
        <Button
            color="primary"
            fullWidth
            variant="text"
        >

        </Button>
    </CardActions>
</Card>);