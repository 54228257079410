import axios from "./index";

class InstituteMeasures {
    static get = () => {
        return axios.get(`${base}`);
    };

    static getMeasures = () => {
        return axios.get(`${base}/measures`);
    };

    static getById = (measureId) => {
        return axios.get(`${base}/${measureId}`);
    };

    static getStatusById = (measureId) => {
        return axios.get(`${base}/status/${measureId}`);
    };

    static getNotificationById = (measureId) => {
        return axios.get(`${base}/notification/${measureId}`);
    };

    static deleteMeasure(measureId) {
        return axios.delete(`${base}/${measureId}`);
    }

    static save = (data) => {
        return axios.post(`${base}`, data);
    };

    static saveMeasureCompleted(measureId) {
        return axios.post(`${base}/${measureId}/measure-completed`);
    }

    static saveStartAnalyse(measureId) {
        return axios.post(`${base}/${measureId}/start-analyse`);
    }

    static saveMeasureRemeasure(measureId) {
        return axios.post(`${base}/${measureId}/remeasure`);
    }
}

let base = "/api/institute-measures";

export default InstituteMeasures;
