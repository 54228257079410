import React, {useEffect, useState} from 'react';
import Teacher from "./Teacher";
import "./TeacherList.css";
import TeacherApi from "../../api/Teachers";
import {useParams} from "react-router-dom";
import ListAlert from "../ListAlert";
import AddIcon from "@mui/icons-material/Add";
import TeacherEdit from "./TeacherEdit";
import {Fab, Grid} from "@mui/material";

const TeacherList = () => {

    const {instituteId} = useParams();

    const [teachers, setTeachers] = useState([]);

    const [openTeacherDialog, setOpenTeacherDialog] = useState(false);

    const handleClickTeacherOpen = () => {
        setOpenTeacherDialog(true);
    };

    const closeTeacherDialog = () => {
        setOpenTeacherDialog(false);
    };

    const getTeachers = () => {
        TeacherApi.getByInstituteId(instituteId).then(response => setTeachers(response.data.data));
    }

    useEffect(() => {
        getTeachers();
    }, []);

    const listItems = teachers.map((teacher) =>
        <Teacher key={teacher.id} teacher={teacher}/>
    );

    return (
        <div>
            <h2>ÖĞRETMENLER
                <Grid container direction="row" alignItems="center" alignContent="stretch">
                    <Grid item>

                    </Grid>
                    <Grid item style={{marginLeft: 'auto'}}>
                        <Fab color="primary" onClick={handleClickTeacherOpen} aria-label="Öğretmen Ekle">
                            <AddIcon/>
                        </Fab>
                    </Grid>
                </Grid>
            </h2>

            <ListAlert listItems={listItems}></ListAlert>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                {listItems}
            </Grid>

            <TeacherEdit closeDialog={closeTeacherDialog} isOpen={openTeacherDialog} instituteId={instituteId}/>
        </div>
    );
};

export default TeacherList;
