import React, {forwardRef} from "react";
import AddBox from "@mui/icons-material/AddBox";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Edit from "@mui/icons-material/Edit";
import SaveAlt from "@mui/icons-material/SaveAlt";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Search from "@mui/icons-material/Search";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Remove from "@mui/icons-material/Remove";
import ViewColumn from "@mui/icons-material/ViewColumn";

export const API_SERVER = process.env.REACT_APP_API_SERVER_URI;

export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};


export const tableLocationsTR = {
    body: {
        emptyDataSourceMessage: 'Gösterilecek kayıt yok'
    },
    toolbar: {
        addRemoveColumns: 'Kolon ekle veya çıkar',
        nRowsSelected: '{0} kayıt (s) seçildi',
        showColumnsTitle: 'Kolonları Göster',
        showColumnsAriaLabel: 'Kolonları Göster',
        exportTitle: 'Dışa Aktar',
        exportAriaLabel: 'Dışa Aktar',
        exportCSVName: 'CSV Dosyası',
        exportPDFName: 'PDF Dosyası',
        searchTooltip: 'Arama',
        searchPlaceholder: 'Arama'
    },
    pagination: {
        labelRowsSelect: 'satır',
        labelDisplayedRows: '{count} satırdan {from}-{to} arası',
        firstTooltip: 'İlk Sayfa',
        previousTooltip: 'Önceki Sayfa',
        nextTooltip: 'Sonraki Sayfa',
        lastTooltip: 'Son Sayfa'
    }
}
