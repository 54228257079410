import React, {useEffect, useState} from 'react';
import {AccountProfile} from "../component/account/AccountProfile";
import {AccountProfileDetails} from "../component/account/AccountProfileDetails";
import {Box, Container, Grid, Typography} from "@mui/material";
import UserApi from "../api/User";

const Account = () => {

    const [userInfo, setUserInfo] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        city: '',
        country: ''
    });

    const getUserInfo = () => {
        UserApi.getMe().then(response => setUserInfo(response.data.data));
    }

    useEffect(() => {
        getUserInfo()
    }, []);

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Typography
                    sx={{mb: 3}}
                    variant="h4"
                >
                    Hesabım
                </Typography>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xs={12}
                    >
                        <AccountProfile userInfo={userInfo}/>
                    </Grid>
                    <Grid
                        item
                        lg={8}
                        md={6}
                        xs={12}
                    >
                        <AccountProfileDetails userInfo={userInfo}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Account;
