import React from 'react';
import {Link} from 'react-router-dom';

const NotFound = () => (
    <div>
        <h1>404 - Aradığınız Sayfa Bulunamadı!</h1>
        <Link to="/">Ana Sayfa</Link>
    </div>
);

export default NotFound;