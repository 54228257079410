import React, {useEffect, useState} from 'react';
import ClassroomApi from "../../api/Classrooms";
import {useParams} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';

import StudentMeasureApi from "../../api/StudentMeasures";
import InstituteMeasureApi from "../../api/InstituteMeasures";
import MaterialTable from "material-table";

import StudentEdit from "../../component/student/StudentEdit";
import {tableIcons, tableLocationsTR} from "../../config/Constant";
import InstituteMeasure from "../../component/institute-measure/InstituteMeasure";
import {useSnackbar} from "notistack";
import {Button, Fab, Grid} from "@mui/material";

import TableChartIcon from '@mui/icons-material/TableChart';

const Measure = () => {

    const {classroomId, measureId} = useParams();

    const {enqueueSnackbar} = useSnackbar();

    const [columns, setColumns] = useState([]);

    const [classroom, setClassroom] = useState({});
    const [measure, setMeasure] = useState();

    const [studentMeasures, setStudentMeasures] = useState([]);

    const [openStudentDialog, setOpenStudentDialog] = useState(false);


    const handleClickStudentOpen = () => {
        setOpenStudentDialog(true);
    };

    const closeStudentDialog = () => {
        setOpenStudentDialog(false);
    };

    const getStudentMeasures = () => {
        StudentMeasureApi.get(measureId, classroomId).then(response => setStudentMeasures(response.data.data));
    }

    const getClassroom = () => {
        ClassroomApi.get(classroomId).then(response => setClassroom(response.data.data));
    }

    const getMeasure = () => {
        InstituteMeasureApi.getById(measureId).then(response => setMeasure(response.data.data));
    }

    const getStudentClassroomReport = () => {
        StudentMeasureApi.getClassroomReport(measureId, classroomId).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', classroom.name + '-' + measure.description + '-raporu.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        ).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Sınıf Raporu hazırlanırken hata oluştu', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }

        });
        ;
    }

    const getStudentStudentReport = (studentMeasure) => {
        StudentMeasureApi.getStudentReport(measureId, classroomId, studentMeasure.student.id).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', studentMeasure.student.name + ' ' + studentMeasure.student.surname + ' -' + classroom.name + '-' + measure.description + '-raporu.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        ).catch(error => {
            if (!error.response || !error.response.data) {
                enqueueSnackbar('Öğrenci Raporu hazırlanırken hata oluştu', {variant: 'error'});
            } else {
                error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
            }

        });
        ;
    }

    const getMeasureColumns = () => {

        let tempColumns = [{title: 'Adı', field: 'student.name', editable: 'never'},
            {title: 'Soyadı', field: 'student.surname', editable: 'never'}
        ];

        if (measure && measure.measureSkills) {
            const editColumns = measure?.stateType === 'MEASURE' ? 'always' : 'never';
            measure.measureSkills.map((measureSkill) => {
                    let skillValueField = 'skillValue' + measureSkill.fieldOrder;
                    let title = measureSkill.skill.name + '(' + measureSkill.skill.unit + ')';
                    let skillInfo = {title: title, field: skillValueField, type: 'numeric', width: 6, resizable: true, editable: editColumns};

                    tempColumns.push(skillInfo);
                }
            );
        }

        setColumns(tempColumns);

    }

    useEffect(() => {
        getClassroom();
        getMeasure();
        getStudentMeasures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getMeasureColumns()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measure]);

    useEffect(() => {
        if (!openStudentDialog) {
            getStudentMeasures();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openStudentDialog]);

    return (
        <div>
            <h2>ÖĞRENCİ ÖLÇÜMLERİ</h2>

            {measure && <InstituteMeasure instituteMeasure={measure} showActionButton={false}/>}

            {measure?.stateType === 'ANALYSE_COMPLETED' &&
                <Button variant="contained" startIcon={<TableChartIcon/>} onClick={getStudentClassroomReport}>Değerlendirme Raporu</Button>
            }

            <MaterialTable
                title={classroom?.name ? (classroom.name + ' (' + classroom.classLevel.name + ')') : ''}
                icons={tableIcons}
                columns={columns}
                data={studentMeasures}

                editable={{
                    onBulkUpdate: changes =>
                        new Promise((resolve, reject) => {
                            const rows = Object.values(changes);
                            const changedStudentMeasures = [];
                            rows.map(row => {
                                const newData = row.newData;
                                const studentMeasure = {
                                    id: newData.id, studentId: newData.student.id, classroomId: newData.classroom.id,
                                    instituteMeasureId: newData.instituteMeasure.id,
                                    skillValue1: newData.skillValue1, skillValue2: newData.skillValue2,
                                    skillValue3: newData.skillValue3, skillValue4: newData.skillValue4,
                                    skillValue5: newData.skillValue5, skillValue6: newData.skillValue6,
                                    skillValue7: newData.skillValue7, skillValue8: newData.skillValue8,
                                    skillValue9: newData.skillValue9, skillValue10: newData.skillValue10,
                                    skillValue11: newData.skillValue11, skillValue12: newData.skillValue12,
                                    skillValue13: newData.skillValue13, skillValue14: newData.skillValue14,
                                    skillValue15: newData.skillValue15
                                };
                                changedStudentMeasures.push(studentMeasure);
                            })

                            StudentMeasureApi.saveAll(changedStudentMeasures).then((response) => {
                                getStudentMeasures();
                                enqueueSnackbar('Kayıt işlemi tamamlandı', {
                                    variant: 'success'
                                });
                                resolve();
                            }).catch(error => {
                                error.response.data?.uiMessages?.errors.map((err) => (
                                    enqueueSnackbar(err?.message, {
                                        variant: 'error'
                                    })
                                ))
                                reject();
                            });

                        })
                }}

                options={{
                    exportButton: true,
                    paging: false,
                    actionsColumnIndex: 0,
                    addRowPosition: "first",
                }}

                actions={[
                    {
                        icon: SaveIcon,
                        tooltip: 'Öğrenci Raporu',
                        onClick: (event, rowData) => {
                            getStudentStudentReport(rowData);
                        }
                    }
                ]}

                localization={tableLocationsTR}
            />
            <Grid container direction="row" alignItems="center" alignContent="stretch" style={{marginTop: '20px'}}>
                <Grid item>

                </Grid>
                <Grid item style={{marginLeft: 'auto'}}>
                    <Fab color="primary" onClick={handleClickStudentOpen} aria-label="Öğrenci Ekle">
                        <AddIcon/>
                    </Fab>
                </Grid>
            </Grid>

            <StudentEdit closeDialog={closeStudentDialog} isOpen={openStudentDialog} classroom={classroom}/>
        </div>
    );
};

export default Measure;
