import axios from "./index";

class Students {
    static getByInstituteId = (instituteId) => {
        return axios.get(`${base}/institutes/${instituteId}`);
    };

    static getByClassroomId = (classroomId) => {
        return axios.get(`${base}/classrooms/${classroomId}`);
    };

    static saveAll = (data) => {
        return axios.post(`${base}/list`, data);
    };

    static save = (data) => {
        return axios.post(`${base}`, data);
    };
}

let base = "/api/students";

export default Students;
