import React, {useEffect, useState} from 'react';
import InstituteMeasureList from "../../component/institute-measure/InstituteMeasureList";
import InstitutesApi from "../../api/Institutes";
import InstituteMeasureApi from "../../api/InstituteMeasures";
import Institute from "../../component/institute/Institute";
import {Grid} from "@mui/material";
import {TotalSummary} from "../../types/TotalSummary";

const InstituteDashboard = () => {
    const [instituteMeasures, setInstituteMeasures] = useState([]);
    const [institutes, setInstitutes] = useState([]);

    const getInstitutes = () => {
        InstitutesApi.get().then(response => setInstitutes(response.data.data));
    }
    const getInstituteMeasures = () => {
        InstituteMeasureApi.get().then(response => setInstituteMeasures(response.data.data));
    }

    const listItems = institutes?.map((institute) =>
        <Institute key={institute.id} institute={institute} onChange={handleChangeInstituteMeasure}/>
    );

    function handleChangeInstituteMeasure() {
        getInstituteMeasures();
    }

    useEffect(() => {
        getInstitutes();
        getInstituteMeasures();
    }, []);

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="flex-start"
            >
                <TotalSummary title="Güncel" color='secondary' total='3'/>
                <TotalSummary title="Tamamlanan" color='error' total='2'/>
                <TotalSummary title="Devam Eden" color='primary' total='2'/>
                <TotalSummary title="Sınıf" color='success' total='2'/>
            </Grid>

            <InstituteMeasureList instituteMeasures={instituteMeasures}/>
            <div style={{width: '100%', height: 120}}>
                {listItems}
            </div>
        </div>
    );
}

export default InstituteDashboard;