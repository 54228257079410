import {useContext, useEffect, useState} from "react";

import CloseIcon from '@mui/icons-material/Close';
import {useSnackbar} from "notistack";
import InstituteMeasureApi from "../../api/InstituteMeasures";
import TrainerApi from "../../api/Trainers";
import {useForm} from 'react-hook-form';
import {SeasonContext} from "../../config/SeasonContext";
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";

const InstituteMeasureEdit = ({closeDialog, isOpen, institute, id}) => {

    const useStyles = makeStyles((theme) => ({
        appBar: {
            position: 'relative',
        }, title: {
            marginLeft: theme.spacing(2), flex: 1,
        },
    }));

    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    const isAddMode = !id;

    const season = useContext(SeasonContext);

    const [measures, setMeasures] = useState([]);
    const [trainers, setTrainers] = useState([]);

    const {enqueueSnackbar} = useSnackbar();

    const classes = useStyles();

    const getInstituteMeasures = () => {
        InstituteMeasureApi.getMeasures().then(response => setMeasures(response.data.data));
    }

    const getTrainers = () => {
        TrainerApi.get().then(response => setTrainers(response.data.data));
    }

    const handleClose = () => {
        reset();
        closeDialog();
    };

    useEffect(() => {
        getInstituteMeasures();
        getTrainers();
    }, [institute, id]);

    const onSubmit = (data) => {

        Object.assign(data, id);
        Object.assign(data, {instituteId: institute.id});
        Object.assign(data, {seasonId: season.id});

        InstituteMeasureApi.save(data)
            .then(() => {
                enqueueSnackbar('Ölçüm eklendi', {variant: 'success'});
                handleClose();
            })
            .catch(error => {
                if (!error.response || !error.response.data) {
                    enqueueSnackbar('Kayıt da hata ile karşılaşıldı', {variant: 'error'});
                } else {
                    error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
                }
            });
    };

    return (

        <Dialog fullScreen open={isOpen} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {institute.name} -Ölçüm Tanımlama - {season?.name}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleSubmit(onSubmit)}>
                            Kaydet
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText>
                        <InputLabel id="measure-label">Kurum</InputLabel> {institute.name}
                    </DialogContentText>

                    <InputLabel id="measure-label">Ölçüm Methodu</InputLabel>

                    <Select
                        labelId="measure-label"
                        label="Ölçüm Methodu"
                        {...register("measure", {required: true})}
                        fullWidth
                    >
                        {measures?.map(mvalue => (
                            <MenuItem value={mvalue}>{mvalue}</MenuItem>
                        ))}
                    </Select>

                    <InputLabel id="measure-label">Eğitmen</InputLabel>

                    <Select
                        labelId="trainerId-label"
                        label="Eğitmen"
                        {...register("trainerId", {required: true})}
                        fullWidth
                    >
                        {trainers?.map(trainer => (
                            <MenuItem value={trainer.id}>{trainer.name} {trainer.surname}</MenuItem>
                        ))}
                    </Select>
                    <InputLabel id="activeType-label">Başlangıç Tarihi</InputLabel>
                    <TextField
                        type="date"
                        placeholder="Başlangıç Tarihi"
                        defaultValue=""
                        className={classes.textField}
                        {...register("startDate", {
                            valueAsDate: true,
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />

                    <InputLabel id="activeType-label">Bitiş Tarihi</InputLabel>
                    <TextField
                        type="date"
                        placeholder="Bitiş Tarihi"
                        defaultValue=""
                        className={classes.textField}
                        {...register("endDate", {
                            valueAsDate: true,
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />

                    <TextField margin="dense" id="description" label="Açıklama" type="text" {...register("description")} fullWidth/>

                    <InputLabel id="activeType-label">Aktiflik Durumu</InputLabel>
                    <Select
                        labelId="activeType-label" defaultValue={'ACTIVE'}
                        {...register("activeType", {required: true})}
                        fullWidth
                    >
                        <MenuItem value="ACTIVE">Aktif</MenuItem>
                        <MenuItem value="PASSIVE">Pasif</MenuItem>
                        <MenuItem value="SUSPEND">Askıda</MenuItem>
                    </Select>

                    <Button color="primary" variant="contained" fullWidth type="submit">
                        Kaydet
                    </Button>

                </DialogContent>
            </form>
        </Dialog>);
};

export default InstituteMeasureEdit;
