import axios from "./index";

class UserApi {
    static getMe = (data) => {
        return axios.get(`${base}/me`, data);
    };

}

let base = "/api/user";

export default UserApi;
