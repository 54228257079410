import React, {useState} from 'react';

import {Link} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {Avatar, Button, Card, CardActions, CardHeader} from "@mui/material";
import {blueGrey, green} from "@mui/material/colors";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import InfoIcon from '@mui/icons-material/Info';
const Classroom = (props) => {


    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(4),
            },
        },
        green: {
            color: '#fff',
            backgroundColor: green[800],
        },
        blueGrey: {
            color: '#fff',
            backgroundColor: blueGrey[500],
        },
        cardAction: {
            marginRight: theme.spacing(2),
        },
    }));

    const classroom = props.classroom;
    const measureId = props.measureId;

    const showActionButton = props.showActionButton;

    const classes = useStyles();

    const [state, setState] = useState({
        raised: false,
        shadow: 1,
    })

    return (

        <Card className="card-content"
              onMouseOver={() => setState({raised: true, shadow: 4})}
              onMouseOut={() => setState({raised: false, shadow: 1})}
              raised={state.raised} zdepth={state.shadow}>

            <CardHeader
                avatar={<Avatar aria-label="recipe" className={classes.green}>{classroom.name}</Avatar>}
                title={classroom.name}
                subheader={classroom.classLevel.name + ' / ' + classroom.teacher.name + ' ' + classroom.teacher.surname}
            />

            {showActionButton &&
                <CardActions>
                    <Link to={`/classroom/${classroom.id}/student`} className={classes.cardAction}>
                        <Button variant="outlined" startIcon={<InfoIcon/>}>DETAY</Button>
                    </Link>
                    {measureId &&
                        <Link to={`/measure/${measureId}/classroom/${classroom.id}`} style={{marginLeft: 'auto'}}>
                            <Button variant="contained" startIcon={<PlaylistAddIcon/>}>BAŞLA</Button>
                        </Link>
                    }
                </CardActions>
            }

        </Card>

    );
};

export default Classroom;
