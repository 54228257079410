import axios from "./index";

class MockApi {
    static getMockStudentData = (instituteId) => {
        return axios.post(`${base}/students/${instituteId}`);
    };
    static getMockMeasureData = (instituteId) => {
        return axios.post(`${base}/measures/${instituteId}`);
    };
}

let base = "/api/supportdeck/mock";

export default MockApi;
