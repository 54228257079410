import {useEffect} from "react";
import CloseIcon from '@mui/icons-material/Close';
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useForm} from "react-hook-form";
import TeacherApi from "../../api/Teachers";
import {useSnackbar} from "notistack";

const TeacherEdit = ({closeDialog, isOpen, instituteId, id}) => {

    const useStyles = makeStyles((theme) => ({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }));

    const isAddMode = !id;

    const {register, handleSubmit, reset, formState: {errors}} = useForm({defaultValues: {activeType: 'ACTIVE'}});

    const classes = useStyles();

    const {enqueueSnackbar} = useSnackbar();

    const handleClose = () => {
        reset();
        closeDialog();
    };

    useEffect(() => {

    }, []);

    const onSubmit = (data) => {

        Object.assign(data, id);
        Object.assign(data, {instituteId: instituteId});

        TeacherApi.save(data)
            .then(() => {
                enqueueSnackbar('Öğretmen eklendi', {variant: 'success'});
                handleClose();
            })
            .catch(error => {
                if (!error.response || !error.response.data) {
                    enqueueSnackbar('Kayıt da hata ile karşılaşıldı', {variant: 'error'});
                } else {
                    error.response.data.uiMessages.errors.map((uiError) => enqueueSnackbar(uiError.message, {variant: 'error'}));
                }

            });
    };

    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Öğretmen Tanımlama
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleSubmit(onSubmit)}>
                            Kaydet
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <TextField autoFocus margin="dense" id="name" label="Adı" type="text" {...register("name")} fullWidth/>
                    <TextField margin="dense" id="surname" label="Soyadı" type="text" {...register("surname")} fullWidth/>
                    <InputLabel id="sex-label">Cinsiyet</InputLabel>
                    <Select
                        labelId="sex-label"
                        label="Cinsiyet"
                        {...register("sex", {required: true})}
                        fullWidth
                    >
                        <MenuItem value='MALE'>Erkek</MenuItem>
                        <MenuItem value='FEMALE'>Kız</MenuItem>
                    </Select>
                    <TextField margin="dense" id="identityNo" label="Kimlik No" type="number" {...register("identityNo", {valueAsNumber: true})}
                               fullWidth/>
                    <TextField margin="dense" id="phoneNumber" label="Telefon" type="number" {...register("phoneNumber")} fullWidth/>
                    <TextField margin="dense" id="email" label="Eposta" type="email" {...register("email")} fullWidth/>
                    <InputLabel id="activeType-label">Aktiflik Durumu</InputLabel>
                    <Select
                        labelId="activeType-label" defaultValue={'ACTIVE'}
                        {...register("activeType", {required: true})}
                        fullWidth
                    >
                        <MenuItem value="ACTIVE">Aktif</MenuItem>
                        <MenuItem value="PASSIVE">Pasif</MenuItem>
                        <MenuItem value="SUSPEND">Askıda</MenuItem>
                    </Select>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default TeacherEdit;

