import React from 'react';

const ListAlert = (props) => {
    const listItems = props.listItems;

    return (
        <div>
            {
                listItems.length === 0 &&
                <i> Liste boş.</i>
            }
        </div>
    );
};

export default ListAlert;
