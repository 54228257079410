import axios from "./index";

class Classrooms {

    static getByInstituteId = (instituteId) => {
        return axios.get(`${base}/institutes/${instituteId}`);
    };

    static getByInstituteIdAndSeasonId = (instituteId, seasonId) => {
        return axios.get(`${base}/institutes/${instituteId}/seasons/${seasonId}`);
    };

    static get = (classroomId) => {
        return axios.get(`${base}/${classroomId}`);
    };

    static save = (data) => {
        return axios.post(`${base}`, data);
    };
}

let base = "/api/classrooms";

export default Classrooms;
